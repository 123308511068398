
<template>
    <div class="contract-section">
        <custom-table :loadData="loadData" :remove="remove" :drawers="drawers" :edit="edit" :pageInfo="pageInfo"
            :statistical="statistical" :filters="filters" :tableColumns="tableColumns" />
    </div>
</template>

<script>
import * as moment from "moment"
import { list, add, remove, update ,getTotalMoney} from "@/api/fix.js"
const type=1
export default {
    data() {
        return {
        
            filters: [
                {
                    name: "项目名称",
                    value: "",
                    property: "name",
                    defaultValue() {
                        return ''
                    },
                    type: "input",
                    clearable: true
                }, {
                    name: "付款日期",
                    property: 'paymentDate',
                    value: null,
                    type: 'date',
                    format(f){
                        if(f){
                            return moment(f).format("YYYY-MM-DD")
                        }else{
                            return null
                        }
                    },
                    defaultValue() {
                        return null
                    },
                    clearable: true
                }
            ],
            drawers: [
                {
                    name: "项目名称",
                    value: "",
                    property: "name",
                    defaultValue() {
                        return ''
                    },
                    type: "input",
                    require: true,
                    clearable: true
                }, {
                    name: "付款日期",
                    property: "paymentDate",
                    value: null,
                    type: 'date',
                    require: true,
                    defaultValue() {
                        return null
                    },
                    clearable: true
                },{
                    name: "付款金额",
                    property: "payAmount",
                    value: 0,
                    type: 'number',
                    min: 0,
                    require: true,
                    defaultValue() {
                        return 0
                    },
                    formatCommit(e) {
                        return e.toFixed(2)
                    },
                    clearable: true
                },{
                    name: "明细",
                    property: "detail",
                    value: 0,
                    type: 'textarea',
                    min: 0,
                    require: true,
                    defaultValue() {
                        return ''
                    },
                    clearable: true
                }
            ],
            tableColumns: [
                {
                    name: "项目名称",
                    props: "name",
                },
                {
                    name: "付款金额",
                    props: "payAmount",
                    isFormat: true,
                    format: (price) => {
                        return "￥" + price
                    }
                }, {
                    name: "付款日期",
                    props: "paymentDate",
                    isFormat: true,
                    format: (date) => {
                        return moment(new Date(date)).format("YYYY-MM-DD")
                    },
                    formatCommit: (date) => {
                        return moment(new Date(date)).format("YYYY-MM-DD")
                    }
                }, 
                {
                    name: "明细",
                    props: "detail",
                    // isFormat: true,
                }, 
                {
                    name: "操作",
                    isEdit: true,
                    edits: ['remove', 'edit']
                }
            ],
            pageInfo: {
                pageSize: 10,
                pageNumber: 1,
                total: 1000
            },
            totalData:0
        }
    },
    computed: {
        statistical() {

            let data = [
                {
                    name: "福利费总额",
                    value: this.totalData
                },
            ]
            return data
        },

    },
    methods: {
    
        async loadData(data) {
            // getTotalMoney().then(({data})=>{
            //     this.totalData=data
            // })
                 let lists=[]
                let total=0
            let res = await list({...data,type})
            if(res.data){
                        this.totalData=res.data.totalAmount
                        lists=res.data.basicPageVo.list
                        total=res.data.basicPageVo.total
            }   else{
                this.totalData=0
            }
            return {
                list:lists   ,
                total
            }
        

        },
        async remove(row) {
            await remove({
                id: row.id
            })
        },
        async edit(data) {
            // delete data['id']
            let httpMethod = data.drawerType == 'edit' ? update : add
            delete data['drawerType']
            data.payAmount*=1
            await httpMethod({...data,type})
        },

    }


}
</script>